<template>
    <Navbar :user="user" />
  <div class="container" v-if="!isLoading">
    <div class="section-title">
      <h2>Add Test Question</h2>
    </div>
    <div class="contact-form">
      <form @submit.prevent="createQuestion" id="contactForm" novalidate="true">
        <div class="row text-start">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label for="name">Titleee</label>
                    <input type="text" v-model="form.title" class="form-control"/>
                    <div v-if="errors.title" class="text-small text-danger text-start">{{ errors.title[0] }}</div>
                </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
                <label for="name">Description</label>
              <textarea class="form-control" v-model="form.description"  cols="30" rows="4"></textarea>
              <div v-if="errors.description" class="text-small text-danger text-start">{{ errors.description[0] }}</div>
            </div>
          </div>
           <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="name">Correct Answer</label>
                <select  v-model="form.correct_answer" class="form-control" >
                  <option value="">Choose Correct Answer</option>
                  <option value="1" >Option One</option>
                  <option value="2" >Option Two</option>
                  <option value="3" >Option Three</option>
                  <option value="4" >Option Four</option>
                </select>
                <div v-if="errors.correct_answer" class="text-small text-danger text-start">{{ errors.correct_answer[0] }}</div>
              <div class="form-text">Choose the right answer from options provided above</div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="name">Question Duration(Seconds)</label>
                <input type="number" v-model="form.question_duration" class="form-control" min="10"/>
                <div v-if="errors.question_duration" class="text-small text-danger text-start">{{ errors.question_duration[0] }}</div>
              <div class="form-text">How long it will take for candidate to answer thes Question</div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12" v-for="(option , index) in form.test_question_options" :key="option.id">
            <div class="form-group">
                <label for="name">Option 
                  <span v-if="index + 1 == 1">One</span>
                  <span v-if="index + 1 == 2">Two</span>
                  <span v-if="index + 1 == 3">Three</span>
                  <span v-if="index + 1 == 4">Four</span>
                </label>
                <input type="text" v-model="option.title" class="form-control" readonly/>
                <div class="form-text text-end text-dark">Note Answers are edited Separately</div>
                <div v-if="errors.test_option1" class="text-small text-danger text-start">{{ errors.test_option1[0] }}</div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 text-center">
            <button type="submit" class="btn default-btn" disabled v-if="submitting">
              <span class="label">{{ value }}</span>
            </button>
            <button type="submit" class="default-btn btn" style="pointer-events: all; cursor: pointer" v-else>
              <span class="label">Save Changes</span>
            </button>
            
          </div>
        </div>
      </form>
    </div>
  </div>
  <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import axios from 'axios'
export default {
  components: { Navbar,Loading },
    data(){
      return{
        isLoading: true,
        submitting: false,
        values: [],
        questionId: this.$route.params.id,
        form:{
          title: '', description: '', correct_answer: '', question_duration: '', test_id: this.$route.params.id, options: []
        },
        user: {},
        textFields: [],
        editor: ClassicEditor,
        token: localStorage.getItem('user_data'),
        errors: {}
          
      }
    },
    methods: {
    createQuestion(){
      this.submitting = true
      this.value = 'Please Wait...'
      let id = this.$route.params.id
      axios.post('https://apitraining.vipawaworks.com/api/test_question/update/' + id,this.form).then(response =>{
        response.data
        this.$router.push({ name: 'TrainerTestQuestionsDetails', params: {id: id}})
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.submitting = false
      })
    },
    getQuestion(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/test_question/profile/' + id).then(response => {
        this.form = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Create Question - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser() 
    this.getQuestion()
  }

}
</script>

<style>
.text-fields-row {
  display: flex;
}
</style>